<template>
  <div>
    <section>
      <v-card color="#232126" dark rounded="xl" class="pa-6 d-flex justify-content-between align-items-center">
          <span class="">{{$t('Count of your questions')}}</span>
          <div class="relative">
            <div class="c1"></div>
            <div class="c2"></div>
            <div class="c3"></div>
            <div class="c4"></div>
            <div class="c5"></div>
            <div class="c6"></div>
            <v-avatar color="white" size="50" class="ms-0 me-4">
              <span class="primary--text f18">{{ totalQuestions }}</span>
            </v-avatar>
          </div>
      </v-card>

      <div v-if="items && items.length > 0">
        <v-card 
          class="my-4 rounded-lg border" 
          v-for="(item, index) in items" 
          :key="index" 
          elevation="0" 
          hover 
          ripple
          :to="`/c/questions/${item.id}`"
        >
          <v-card-title class="d-flex justify-space-between align-center">
            <div>
              <span v-if="item.answers" class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> {{
                  item.answers.length
              }} {{ $t("Answer") }}</span>
              <span v-else class="blue lighten-5 blue--text text--darken-1 p-2 rounded-lg f12"> 0 {{ $t("Answer") }}</span>
            </div>
            <div>
              <span class="f13 font-weight-light"> {{ item.createDate | moment("from", "now") }} </span>
            </div>
          </v-card-title>

          <v-card-text>
            <p class="f14 text-justify" v-html="item.question"></p>
          </v-card-text>

          <v-divider class="m-0" dark></v-divider>

          <v-card-actions class="d-flex justify-space-between align-center">
            <div v-if="item.answers && item.answers.length">
              <div class="d-flex flex-row align-items-center">
                <section class="d-flex flex-row avatar-group right-to-left">
                  <span v-for="(answer, index) in item.answers.slice(0, 3)" :key="index"
                    class="avatars-group__item avatar">
                    <v-avatar size="36" color="primary" class="bordered">
                      <img :src="answer.doctorProfilePic" :alt="answer.doctorFullName ? answer.doctorFullName : ''"
                        v-if="answer.doctorProfilePic && index < 2" />

                      <span v-else-if="index < 2" class="white--text text-small">
                        {{ answer.doctorFullName ? answer.doctorFullName.slice(0, 1) : "" }}
                      </span>

                      <span v-else class="white--text text-small">
                        ...
                      </span>
                    </v-avatar>
                  </span>
                </section>
                <div>
                  <span class="f12 font-weight-normal ps-1" v-if="item.answers.length == 1">
                    {{ item.answers[0].doctorFullName ? item.answers[0].doctorFullName : '' }}
                  </span>
                  <span class="f12 font-weight-normal ps-1" v-if="item.answers.length > 1">
                    {{ item.answers.length }} {{ $t('specialist') }}
                  </span>
                </div>
              </div>
            </div>
            <div v-else>
                  <span class="bg-harmonies text-paris-pink p-2 rounded-lg f12">
                    {{ $t("Not answered") }}
                  </span>
                </div>
            <div>
              <div class="text-left mt-1">
                <div class="d-flex d-inline-flex align-items-center border border-primary rounded primary--text" v-if="item.userLikeQuestions && !item.userLikeQuestions.find(x => x.mobile == $store.getters.mobileNumber)">
                  <iconly type="bold" name="heart" class="ms-1 me-0 wh-20"></iconly>
                  <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                    {{
                        item.userLikeQuestions.length
                    }}
                  </span>
                  <span v-else class="me-1 f12">0</span>
                </div>
                <div class="d-flex d-inline-flex align-items-center primary rounded white--text" v-else>
                  <iconly type="bold" name="heart" class="ms-1 me-0 wh-20" color="white"></iconly>
                  <span v-if="item.userLikeQuestions && item.userLikeQuestions.length" class="me-1 f12">
                    {{
                        item.userLikeQuestions.length
                    }}
                  </span>
                  <span v-else class="me-1 f12">0</span>
                </div>
                <div class=" d-flex d-inline-flex align-items-center border border-primary rounded primary--text ms-2">
                  <iconly type="bold" name="show" class="ms-1 me-0 wh-20"></iconly>
                  <span class="me-1 f12">{{ item.userViews.length }}</span>
                </div>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </div>
      
      <span class="text-center f16 d-block mt-8" v-else>
        {{$t('No results found')}}
      </span>

      <infinite-loading @infinite="infiniteHandler">
        <div slot="spinner">
          <!-- {{$t('Loading...')}} -->
          <v-icon>mdi-selection-ellipse spin</v-icon>
        </div>
        <div slot="no-more">
          <!-- {{$t('No more items')}} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
        <div slot="no-results">
          <!-- {{$t('No results found')}} -->
          <v-icon>mdi-access-point-off</v-icon>
        </div>
      </infinite-loading>
    </section>

    <v-footer app fixed padless color="transparent" class="mb-2">
      <v-container fluid class="py-0 my-0 d-flex justify-content-end">
        <v-btn color="primary" rounded to="/c/questions/add"
          >{{ $t("Add question") }} +
        </v-btn>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();


export default {
  data() {
    return {
      items: [],
      page: 1,
      size: 12,

      totalQuestions: 0,
    };
  },
  methods: {
    getUserQuestionPaging(page, size) {
      apiService.getUserQuestionPaging(this.$store.getters.clientId, page, size)
        .then((response) => {
          this.items = response.data.content;
          this.totalQuestions = response.data.totalElements;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    infiniteHandler($state) {
      apiService.getUserQuestionPaging(this.$store.getters.clientId, this.page, this.size)
      .then((response) => {
        if (response.data.content.length) {
          response.data.content.forEach(element => {
            this.items.push(element);
          });
          
          this.page += 1;
          $state.loaded();
        }
        else {
          $state.complete();
        }
      })
      .catch((err) => {
        console.log(err);
        $state.error();
      });
    }
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
    
    this.getUserQuestionPaging(0, this.size);
  },
};
</script>
<style scoped>
.c1 {
  width: 8px;
  height: 8px;
  background-color: #ac60f9;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M77.5,45.5m-3.5,0a3.5,3.5 0,1 1,7 0a3.5,3.5 0,1 1,-7 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 2.1s linear alternate infinite;
}
.c2 {
  width: 5px;
  height: 5px;
  background-color: #f7a834;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M78.5,61.5m-1.5,0a1.5,1.5 0,1 1,3 0a1.5,1.5 0,1 1,-3 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 1.9s linear alternate infinite;
}
.c3 {
  width: 4px;
  height: 4px;
  background-color: #7bf7fd;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M56.5,65.5m-1.5,0a1.5,1.5 0,1 1,3 0a1.5,1.5 0,1 1,-3 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 0.8s linear alternate infinite;
}

.c4 {
  width: 8px;
  height: 8px;
  background-color: #ac60f9;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M6.5,28.5m-3.5,0a3.5,3.5 0,1 1,7 0a3.5,3.5 0,1 1,-7 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 1.8s linear alternate infinite;
}

.c5 {
  width: 4px;
  height: 4px;
  background-color: #7ffdfd;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M2,2m-2,0a2,2 0,1 1,4 0a2,2 0,1 1,-4 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 1.5s linear alternate infinite;
}
.c6 {
  width: 5px;
  height: 5px;
  background-color: #ffab2e;
  position: absolute;
  offset-distance: 0%;
  offset-path: path('M18.5,2.5m-2.5,0a2.5,2.5 0,1 1,5 0a2.5,2.5 0,1 1,-5 0');
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: move 1s ease alternate infinite;
}

@keyframes move {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1.5);
    }
}

</style>